import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Card } from "../Card";
import { Container } from "../Container";

import "./KeyFeature.scss";

import { Images } from "../../assets/images";
import { useMedia } from "../../assets/hooks/useMedia";

gsap.registerPlugin(ScrollTrigger);

const CARD_VALUES = [
  {
    id: "id1",
    icon: Images.SvgReliableSolution,
    title: "Reliable Solutions",
    text: "Provide intelligent solutions breaking any form of complexity through our extensive experience.",
    style: { position: "absolute", right: 192, top: 600 },
    mobileStyle: { position: "absolute", right: 24, top: 900 },
  },
  {
    id: "id2",
    icon: Images.SvgPromptDelivery,
    title: "Prompt Delivery",
    text: "Plan, develop and deliver projects promptly as how it is promised at the beginning.",
    style: { position: "absolute", right: 0, top: 900 },
    mobileStyle: { position: "absolute", right: 0, top: 948 },
  },
  {
    id: "id3",
    icon: Images.SvgInteractiveDesign,
    title: "Interactive Designs",
    text: "Plan and design intuitively to make interfaces more receptive for the users.",
    style: { position: "absolute", right: 192, top: 1200 },
    mobileStyle: { position: "absolute", right: 24, top: 1000 },
  },
  {
    id: "id4",
    icon: Images.SvgCostEffectiveSolution,
    title: "Cost-Effective Solutions",
    text: "Solutions that are pocket friendly and add more value to your business with better ROI.",
    style: { position: "absolute", right: 0, top: 1500 },
    mobileStyle: { position: "absolute", right: 0, top: 1050 },
  },
  {
    id: "id5",
    icon: Images.SvgImpressiveCommunication,
    title: "Impressive Communication",
    text: "Well organized to keep constantly in touch with our clients when they need us.",
    style: { position: "absolute", right: 192, top: 1800 },
    mobileStyle: { position: "absolute", right: 24, top: 1100 },
  },
  {
    id: "id6",
    icon: Images.SvgServiceQuality,
    title: "Quality Of Service",
    text: "Ensure customer satisfaction through continuously delivering excellent services flawlessly.",
    style: { position: "absolute", right: 0, top: 2100 },
    mobileStyle: { position: "absolute", right: 0, top: 1150 },
  },
];

export const KeyFeature = () => {
  const keyRef = useRef(null);
  const textRef = useRef(null);
  const { isMobile } = useMedia();

  // key feature cards' scroll animation
  useEffect(() => {
    const about = document?.querySelector(".menu__option-wrapper--about");

    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: "#key",
        start: "top top",
        end: "+=1700",
        scrub: 2,
        onEnter: () => {
          about?.classList.add("active");
        },
        onLeave: () => {
          about?.classList.remove("active");
        },
        onEnterBack: () => {
          about?.classList.add("active");
        },
      },
    });
    if (!isMobile) {
      tl1
        .to("#id1", { y: -600 })
        .to("#id2", { y: -850 })
        .to("#id3", { y: -1100 })
        .to("#id4", { y: -1350 })
        .to("#id5", { y: -1600 })
        .to("#id6", { y: -1850 });
    } else {
      gsap.set("#num1", { autoAlpha: 1, y: 0 });
      gsap.set("#num2", { autoAlpha: 0, y: 10 });
      gsap.set("#num3", { autoAlpha: 0, y: 10 });
      gsap.set("#num4", { autoAlpha: 0, y: 10 });
      gsap.set("#num5", { autoAlpha: 0, y: 10 });
      gsap.set("#num6", { autoAlpha: 0, y: 10 });
      tl1
        .to(textRef.current, { autoAlpha: 0 })
        .to("#id1", { y: -750 })
        .to("#num1", { autoAlpha: 0, y: -10 })
        .to("#num2", { autoAlpha: 1, y: 0 })
        .to("#id2", { y: -775 })
        .to("#num2", { autoAlpha: 0, y: -10 })
        .to("#num3", { autoAlpha: 1, y: 0 })
        .to("#id3", { y: -800 })
        .to("#num3", { autoAlpha: 0, y: -10 })
        .to("#num4", { autoAlpha: 1, y: 0 })
        .to("#id4", { y: -825 })
        .to("#num4", { autoAlpha: 0, y: -10 })
        .to("#num5", { autoAlpha: 1, y: 0 })
        .to("#id5", { y: -850 })
        .to("#num5", { autoAlpha: 0, y: -10 })
        .to("#num6", { autoAlpha: 1, y: 0 })
        .to("#id6", { y: -875 });
    }
  }, [isMobile]);

  return (
    <Container title="Our Key Features">
      <div className="key-feature" ref={keyRef}>
        <div className="key-feature__paragraph isHover">
          <p>
            Enabling businesses to turn their idea into reality. High paced
            execution, fast iterations, and delivery on time, every time.
          </p>
          <p ref={textRef}>
            We have a very well rounded skill sets across different verticals of
            software development. Whether it is web application, mobile
            application, desktop applications or even kernel hacking, we have an
            inhouse expert that can understand the complexities and come up with
            the solution, The Right Way™.
          </p>
        </div>
        <div className="key-feature__cards">
          {CARD_VALUES.map(({ icon, title, text, id, style, mobileStyle }) => (
            <div className="key-feature__box" key={id} id={id}>
              <Card
                title={title}
                Icon={icon}
                text={text}
                variant="default"
                style={isMobile ? mobileStyle : style}
              />
            </div>
          ))}
        </div>
        {isMobile && (
          <div className="key-feature__counter">
            <div className="key-feature__counter-box">
              <div id="num1">1</div>
              <div id="num2">2</div>
              <div id="num3">3</div>
              <div id="num4">4</div>
              <div id="num5">5</div>
              <div id="num6">6</div>
            </div>
            <span className="key-feature__counter--all">/6</span>
          </div>
        )}
      </div>
    </Container>
  );
};
