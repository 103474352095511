import React, { useState } from "react";
import cn from "classnames";

import "./Contact.scss";

import { Container } from "../Container";

import { Images } from "../../assets/images";

import { useMedia } from "../../assets/hooks/useMedia";

import { convertToKilobyte } from "../../assets/utils/convertToKilobyte";

export const Contact = () => {
  const { isMobile } = useMedia();
  const [value, setValue] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: "",
  });
  const [file, setFile] = useState(null);

  const API = "/api";
  const MESSAGE_LENGTH = isMobile ? 50 : 200;

  const handleSetFile = (e) => {
    e.stopPropagation();
    setFile(e.target.files[0]);
  };

  const clearFile = (e) => {
    document.querySelector("#contact_upload").value = "";
    setFile(null);
  };

  const sendMail = (event) => {
    let form = event.currentTarget;

    fetch(form.action, { method: form.method, body: new FormData(form) })
      .then((response) => {
        if (response.ok) {
          form.reset();
          alert("Mail successfully sent");
        } else {
          alert(
            `HTTP Error ${response.status} while submitting the form. Please try again later.`
          );
        }
      })
      .catch((err) => {
        alert(
          `Error ${err} while submitting the form. Please try again later.`
        );
      });
  };

  return (
    <Container title="Contact us" contact>
      <div className="contact">
        <form
          className="contact__form"
          action={`${API}/mail`}
          method="post"
          onSubmit={(e) => {
            sendMail(e);
            e.preventDefault();
          }}
        >
          <div className="contact__form-name">
            <label htmlFor="name" className="contact__form-label">
              <input
                className="contact__form-input"
                id="name"
                name="firstname"
                placeholder="Name"
                required
                value={value.firstname}
                onChange={(e) =>
                  setValue({ ...value, firstname: e.target.value })
                }
              />
              <span>Name</span>
            </label>
            <label htmlFor="surname" className="contact__form-label">
              <input
                className="contact__form-input"
                id="surname"
                name="surname"
                placeholder="Surname (optional)"
                value={value.lastname}
                onChange={(e) =>
                  setValue({ ...value, lastname: e.target.value })
                }
              />
              <span>
                Surname <span className="contact__optional">(optional)</span>
              </span>
            </label>
          </div>
          <div className="contact__form-email">
            <label htmlFor="email" className="contact__form-label">
              <input
                className="contact__form-input"
                id="email"
                name="email"
                placeholder="Email"
                required
                value={value.email}
                onChange={(e) => setValue({ ...value, email: e.target.value })}
              />
              <span>Email </span>
            </label>
            <label htmlFor="phone" className="contact__form-label">
              <input
                className="contact__form-input"
                id="phone"
                name="phone"
                placeholder="Phone number (optional)"
                value={value.phone}
                onChange={(e) => setValue({ ...value, phone: e.target.value })}
              />
              <span>
                Phone number
                <span className="contact__optional"> (optional)</span>
              </span>
            </label>
          </div>

          <div className="contact__form-textarea">
            <label htmlFor="textarea" className="contact__form-label textarea">
              <textarea
                className={cn("contact__form-input textarea", {
                  "contact__form-label textarea--with-scrollBar":
                    value.message.length > MESSAGE_LENGTH,
                })}
                id="textarea"
                name="message"
                placeholder="Your message"
                value={value.message}
                onChange={(e) =>
                  setValue({ ...value, message: e.target.value })
                }
              />
              <span>Message </span>
            </label>
            <div className="contact__form-attach-block">
              <div className="contact__form-attach">
                <label
                  className="contact__form-attach-icon"
                  htmlFor="contact_upload"
                >
                  <Images.SvgAttach />
                </label>
                <input
                  type="file"
                  name="file"
                  className="contact__form-attach-input"
                  id="contact_upload"
                  onChange={handleSetFile}
                />
              </div>
              {file && (
                <div className="contact__uploaded-file">
                  <span className="contact__uploaded-file-name">
                    {file.name}{" "}
                    <span className="contact__uploaded-file-size">
                      {convertToKilobyte(file.size)}
                    </span>
                  </span>
                  <div onClick={clearFile}>
                    <Images.SvgCross />
                  </div>
                </div>
              )}
            </div>
          </div>
          <button
            className={cn("contact__form-button isHover", {
              "contact__form-button--active":
                value.firstname !== "" && value.email !== "",
            })}
          >
            Submit
          </button>
        </form>
        <div className="contact__reach">
          <div className="contact__reach-email">
            <div className="contact__reach-icon">
              <Images.SvgEmail />
            </div>
            <div className="contact__reach-text">
              <a href="mailto:contact@hack24x7.com">
                contact@hack24x7.com <br />
                <span>online quotation</span>
              </a>
            </div>
          </div>
          <div className="contact__reach-location">
            <div className="contact__reach-icon">
              <Images.SvgMap />
            </div>
            <div className="contact__reach-text">
              New York, New York <br />
              <span>office location</span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
