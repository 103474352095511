import { StackImages } from "../../../assets/images/stack";

export const expertsData = [
  {
    id: 1,
    name: "JavaScript",
    icon: StackImages.SvgJavascript,
  },
  {
    id: 2,
    name: "TypeScript",
    icon: StackImages.SvgTypescript,
  },
  {
    id: 3,
    name: "Angular",
    icon: StackImages.SvgAngular,
  },
  {
    id: 4,
    name: "React",
    icon: StackImages.SvgReact,
  },
  {
    id: 5,
    name: "Node.JS",
    icon: StackImages.SVgNode,
  },
  {
    id: 6,
    name: "HTML5",
    icon: StackImages.SvgHtml,
  },
  {
    id: 7,
    name: "CSS3",
    icon: StackImages.SvgCss,
  },
  {
    id: 8,
    name: "Django",
    icon: StackImages.SvgDjango,
  },
  {
    id: 9,
    name: "Ruby On Rails",
    icon: StackImages.SvgRuby,
  },
  {
    id: 10,
    name: "Chrome Plugin",
    icon: StackImages.SvgChromePlugin,
  },
  {
    id: 11,
    name: "SCSS",
    icon: StackImages.SvgSass,
  },
  {
    id: 12,
    name: "Java",
    icon: StackImages.SvgJava,
  },
  {
    id: 13,
    name: "PHP",
    icon: StackImages.SvgPhp,
  },
  {
    id: 14,
    name: "Scala",
    icon: StackImages.SvgScala,
  },
  {
    id: 15,
    name: "Haskell",
    icon: StackImages.SvgHaskell,
  },
  {
    id: 16,
    name: "Python",
    icon: StackImages.SvgPython,
  },
  {
    id: 17,
    name: "Flask Server",
    icon: StackImages.SvgFlask,
  },
  {
    id: 18,
    name: "Kafka",
    icon: StackImages.SvgKafka,
  },
  {
    id: 19,
    name: "ZeroMQ",
    icon: StackImages.SvgZero,
  },
  {
    id: 20,
    name: "Spring Framework",
    icon: StackImages.SvgSpring,
  },
];

export const databseData = [
  {
    id: 1,
    name: "MySQL / Maria DB",
    icon: StackImages.SvgMysql,
  },
  {
    id: 2,
    name: "Postgres",
    icon: StackImages.SvgPostgress,
  },
  {
    id: 3,
    name: "Cassandra",
    icon: StackImages.SvgCassandra,
  },
  {
    id: 4,
    name: "Couchbase",
    icon: StackImages.SvgCouchbase,
  },
];

export const testingData = [
  {
    id: 1,
    name: "Jenkins",
    icon: StackImages.SvgJenkins,
  },
  {
    id: 2,
    name: "Travis CI",
    icon: StackImages.SvgTravis,
  },
  {
    id: 3,
    name: "Circle CI",
    icon: StackImages.SvgCircle,
  },
  {
    id: 4,
    name: "Karma",
    icon: StackImages.SvgKarma,
  },
  {
    id: 5,
    name: "Concourse",
    icon: StackImages.SvgConcourse,
  },
  {
    id: 6,
    name: "Spinnaker",
    icon: StackImages.SvgSpinnaker,
  },
];

export const computingData = [
  {
    id: 1,
    name: "Amazon S3",
    icon: StackImages.SvgAmazon,
  },
  {
    id: 2,
    name: "Firebase",
    icon: StackImages.SvgFirebase,
  },
  {
    id: 3,
    name: "CDN",
    icon: StackImages.SvgCdn,
  },
  {
    id: 4,
    name: "Kubernetes",
    icon: StackImages.SvgKubernetes,
  },
  {
    id: 5,
    name: "Amazon AWS",
    icon: StackImages.SvgAmazonaws,
  },
  {
    id: 6,
    name: "Google Cloud Services",
    icon: StackImages.SvgGoogleCloud,
  },
];

export const integrationData = [
  {
    id: 1,
    name: "Facebook API",
    icon: StackImages.SvgFacebookApi,
  },
  {
    id: 2,
    name: "Google API",
    icon: StackImages.SvgGoogleApi,
  },
  {
    id: 3,
    name: "Twitter API",
    icon: StackImages.SvgTwitterApi,
  },
  {
    id: 4,
    name: "Zapier",
    icon: StackImages.SvgZapier,
  },
  {
    id: 5,
    name: "IFTTT",
    icon: StackImages.SvgIfttt,
  },
];

export const infrastructureData = [
  {
    id: 1,
    name: "Linux",
    icon: StackImages.SvgLinux,
  },
  {
    id: 2,
    name: "Docker",
    icon: StackImages.SvgDocker,
  },
  {
    id: 3,
    name: "VMware",
    icon: StackImages.SvgVmware,
  },
  {
    id: 4,
    name: "VirtualBox",
    icon: StackImages.SvgVirtualBox,
  },
  {
    id: 5,
    name: "Shell Scripting",
    icon: StackImages.SvgShell,
  },
  {
    id: 6,
    name: "Maliman",
    icon: StackImages.SvgMaliman,
  },
  {
    id: 7,
    name: "SMTP - Postfix",
    icon: StackImages.SvgPostfix,
  },
  {
    id: 8,
    name: "Software Defined Networking",
    icon: StackImages.SvgSoftwareNetworking,
  },
  {
    id: 9,
    name: "DNS - Bind / DJBDNS",
    icon: StackImages.SvgDns,
  },
  {
    id: 10,
    name: "Firewall Configuration (Iptables, Ufw, Pf)",
    icon: StackImages.SvgFirewall,
  },
];

export const iotData = [
  {
    id: 1,
    name: "Remote Monitoring",
    icon: StackImages.SvgRemoteMonitoring,
  },
  {
    id: 2,
    name: "Home Automation",
    icon: StackImages.SvgHomeAutomation,
  },
  {
    id: 3,
    name: "Arduino",
    icon: StackImages.AvgArduino,
  },
  {
    id: 4,
    name: "Beaglebone Black",
    icon: StackImages.SvgBeagleBone,
  },
  {
    id: 5,
    name: "Electronic Locks",
    icon: StackImages.SvgElectroLock,
  },
  {
    id: 6,
    name: "Humidity Sensor",
    icon: StackImages.SvgHumSensor,
  },
  {
    id: 7,
    name: "Door Lock",
    icon: StackImages.SvgDoorLock,
  },
  {
    id: 8,
    name: "Raspberry PI",
    icon: StackImages.SvgRaspberry,
  },
  {
    id: 9,
    name: "Omega 2",
    icon: StackImages.SVgOmega,
  },
  {
    id: 10,
    name: "Alexa Integration",
    icon: StackImages.SvgAlexa,
  },
  {
    id: 11,
    name: "Temperature Sensor",
    icon: StackImages.SvgTempSensor,
  },
  {
    id: 12,
    name: "Biometric Device Based Applications (Attendance, Access Control)",
    icon: StackImages.SVgBiometric,
  },
  {
    id: 13,
    name: "Smart Card-Based Access Control",
    icon: StackImages.SvgSmartCard,
  },
];
