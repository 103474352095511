import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import "./App.scss";

import { BackgroundText } from "./Components/BackgroundText";
import { Contact } from "./Components/Contact";
import { Header } from "./Components/Header";
import { KeyFeature } from "./Components/KeyFeature";
import { Loader } from "./Components/Loader";
import { Logo } from "./Components/Logo";
import { Process } from "./Components/Process";
import { Service } from "./Components/Service";
import { ServicesForOffer } from "./Components/ServicesForOffer";
import { Technology } from "./Components/Technology";

import { CustomMouse } from "./assets/utils/customMouse";
import { useMedia } from "./assets/hooks/useMedia";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

export const App = () => {
  const { isMobile } = useMedia();
  const { ref } = useInView({
    threshold: 0,
  });
  const appRef = useRef(null);
  const logoRef = useRef(null);
  const scrollMouseRef = useRef(null);
  const headerMenusRef = useRef(null);
  const headerHamburgerRef = useRef(null);
  const keyRef = useRef(null);
  const serviceRef = useRef(null);
  const offerRef = useRef(null);
  const processRef = useRef(null);
  const technologyRef = useRef(null);
  const contactRef = useRef(null);
  const [filledLogo, setFilledLogo] = useState(false);

  // logo animation
  useEffect(() => {
    const app = appRef.current;
    const logo = logoRef.current;

    gsap.fromTo(
      logo,
      {
        fontSize: isMobile ? "58px" : "236px",
        yPercent: isMobile ? 0 : 50,
        webkitTextStrokeWidth: 2,
        top: isMobile ? 0 : 100,
      },
      {
        fontSize: isMobile ? "24px" : "32px",
        yPercent: isMobile ? -50 : 0,
        webkitTextStrokeWidth: 0.3,
        top: isMobile ? 50 : 60,
        scrollTrigger: {
          trigger: app,
          start: "top top",
          end: "900px top",
          scrub: 1,
        },
      }
    );
  }, [isMobile]);

  // header&scroll-mouse animation
  useEffect(() => {
    const app = appRef.current;
    const scrollMouse = scrollMouseRef.current;
    const headerMenus = headerMenusRef.current;
    const headerHamburger = headerHamburgerRef.current;

    const appTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: app,
        start: "top -100px",
        end: "top -100px",
        scrub: 0.2,
      },
    });

    appTimeline.to(scrollMouse, { opacity: 0, y: -1 });
    if (!isMobile) {
      appTimeline
        .to(headerMenus, { opacity: 0, y: -1 })
        .fromTo(
          headerHamburger,
          { opacity: 0, y: -10, display: "none" },
          { opacity: 1, y: 0, display: "flex" }
        );
    }
  }, [filledLogo, isMobile]);

  // containers' scroll animation
  useEffect(() => {
    const containers = gsap.utils.toArray(".app__page");
    const menuItems = document?.querySelectorAll(".menu__item");
    const triggers = containers.map((container, index) => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: container,
          start: "top bottom",
          end: "top top",
          scrub: true,
        },
      });

      tl.fromTo(
        containers[index - 1],
        {
          autoAlpha: 1,
          scale: 1,
        },
        {
          autoAlpha: 0,
          scale: 0.98,
        }
      );

      return tl.scrollTrigger;
    });

    menuItems.forEach((link, i) => {
      const element = document.querySelector(link.getAttribute("href"));
      const trigger = triggers.filter((t) => t.trigger === element)[0];

      link.addEventListener("click", (e) => {
        e.preventDefault();
        gsap.to(window, { scrollTo: trigger.end, overwrite: true });
      });
    });
  }, [filledLogo]);

  // background text animation
  document.body.style.overflow = "auto";
  gsap.utils.toArray(".background-text__item").forEach((section, index) => {
    const [x, xEnd] = index % 2 ? ["300%", "-300%"] : ["-300%", "300%"];

    const sloganTimeline = gsap.timeline({
      scrollTrigger: {
        trigger: appRef.current,
        scrub: 1,
        start: isMobile ? "top -=700px" : "top -=300px",
        end: "+=3800px",
        toggleClass: { targets: ".background-text", className: "disable" },
      },
    });

    sloganTimeline.fromTo(
      section,
      { x },
      {
        x: xEnd,
      }
    );
  });

  // menu buttons get active
  useEffect(() => {
    const home = document?.querySelector(".menu__option-wrapper--home");
    const about = document?.querySelector(".menu__option-wrapper--about");
    const service = document?.querySelector(".menu__option-wrapper--service");
    const process = document?.querySelector(".menu__option-wrapper--process");
    const technology = document?.querySelector(
      ".menu__option-wrapper--technology"
    );
    const headerContactLink = document?.querySelector(".header__contact");

    gsap.timeline({
      scrollTrigger: {
        trigger: appRef.current,
        start: "top bottom",
        end: "+=2500px",
        onEnter: () => {
          home?.classList.add("active");
        },
        onLeave: () => {
          home?.classList.remove("active");
        },
        onEnterBack: () => {
          home?.classList.add("active");
        },
        onLeaveBack: () => {
          home?.classList.remove("active");
        },
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: keyRef.current,
        onEnter: () => {
          about?.classList.add("active");
        },
        onLeave: () => {
          about?.classList.remove("active");
        },
        onEnterBack: () => {
          about?.classList.add("active");
        },
        onLeaveBack: () => {
          about?.classList.remove("active");
        },
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: serviceRef.current,
        onEnter: () => {
          about?.classList.add("active");
        },
        onLeave: () => {
          about?.classList.remove("active");
        },
        onEnterBack: () => {
          about?.classList.add("active");
        },
        onLeaveBack: () => {
          about?.classList.remove("active");
        },
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: offerRef.current,
        onEnter: () => {
          service?.classList.add("active");
        },
        onLeave: () => {
          service?.classList.remove("active");
        },
        onEnterBack: () => {
          service?.classList.add("active");
        },
        onLeaveBack: () => {
          service?.classList.remove("active");
        },
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: processRef.current,
        onEnter: () => {
          process?.classList.add("active");
        },
        onLeave: () => {
          process?.classList.remove("active");
        },
        onEnterBack: () => {
          process?.classList.add("active");
        },
        onLeaveBack: () => {
          process?.classList.remove("active");
        },
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: technologyRef.current,
        onEnter: () => {
          technology?.classList.add("active");
        },
        onLeave: () => {
          technology?.classList.remove("active");
        },
        onEnterBack: () => {
          technology?.classList.add("active");
        },
        onLeaveBack: () => {
          technology?.classList.remove("active");
        },
      },
    });
    gsap.timeline({
      scrollTrigger: {
        trigger: contactRef.current,
        onEnter: () => {
          headerContactLink?.classList.add("active");
        },
        onLeave: () => {
          headerContactLink?.classList.remove("active");
        },
        onEnterBack: () => {
          headerContactLink?.classList.add("active");
        },
        onLeaveBack: () => {
          headerContactLink?.classList.remove("active");
        },
      },
    });
  });

  return (
    <div id="home">
      <div className="app__background"></div>
      <div
        ref={appRef}
        className={cn("app", {
          "app--overflow": !filledLogo,
        })}
      >
        <Loader />
        <CustomMouse />
        <div ref={ref}>
          <Header
            filled={filledLogo}
            headerMenusRef={headerMenusRef}
            headerHamburgerRef={headerHamburgerRef}
          />
        </div>
        <Logo
          filled={filledLogo}
          setFilled={setFilledLogo}
          logoRef={logoRef}
          scrollMouseRef={scrollMouseRef}
        />

        {filledLogo && (
          <>
            <BackgroundText />
            <div className="app__container">
              <div className="app__page" id="key" ref={keyRef}>
                <KeyFeature />
              </div>
              <div className="app__page" id="service" ref={serviceRef}>
                <Service />
              </div>
              <div className="app__page" id="offer" ref={offerRef}>
                <ServicesForOffer />
              </div>
              <div className="app__page" id="process" ref={processRef}>
                <Process />
              </div>
              <div className="app__page" id="technology" ref={technologyRef}>
                <Technology />
              </div>
              <div className="app__page" id="contact" ref={contactRef}>
                <Contact />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
