import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";

import "./customMouse.scss";

export const CustomMouse = () => {
  const mouseRef = useRef(null);
  const ball = mouseRef?.current;

  useEffect(() => {
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.1;

    const xSet = gsap.quickSetter(ball, "x", "px");
    const ySet = gsap.quickSetter(ball, "y", "px");

    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;
    });

    gsap.ticker.add(() => {
      // adjust speed for higher refresh monitors
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });
  }, [ball]);

  gsap.utils.toArray(".isHover, h1").forEach((el) => {
    el.addEventListener("mouseenter", () => {
      gsap.to(ball, { scale: 1.5, duration: 0.25 });
    });
    el.addEventListener("mouseleave", () => {
      gsap.to(ball, { scale: 1, duration: 0.25 });
    });
  });

  gsap.utils.toArray(".isNotVisible").forEach((el) => {
    el.addEventListener("mouseenter", () => {
      gsap.to(ball, { autoAlpha: 0, duration: 0.05 });
    });
    el.addEventListener("mouseleave", () => {
      gsap.to(ball, { autoAlpha: 1, duration: 0.05 });
    });
  });

  return <div className="custom-mouse" ref={mouseRef}></div>;
};
