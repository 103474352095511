import React from "react";
import Tilt from "react-parallax-tilt";
import cn from "classnames";

import "./Card.scss";

export const Card = ({
  Icon,
  title,
  text,
  variant,
  style,
  cardRef,
  cardStyle,
  className,
}) => {
  return (
    <>
      <Tilt
        glarePosition="all"
        transitionSpeed={20}
        tiltMaxAngleX={1}
        tiltMaxAngleY={1}
        style={style}
      >
        <div
          className={cn(`card ${className}`, {
            "card--service": variant === "service",
            "card--process": variant === "process",
            "card--technology": variant === "technology",
            "card--key": variant === "key",
          })}
          style={cardStyle}
          ref={cardRef}
          title={variant === "technology" && title.length > 10 ? title : null}
        >
          {Icon && (
            <Icon
              className={cn("card__icon", {
                "card__icon--size":
                  variant === "process" || variant === "service",
                "card__icon--technology": variant === "technology",
              })}
            />
          )}
          <div className="card__text isHover">
            <div
              className={cn("card__title", {
                "card__title--technology": variant === "technology",
              })}
            >
              {title}
            </div>
            <div
              className={cn("card__paragraph", {
                "card__paragraph--service": variant === "service",
              })}
            >
              {text}
            </div>
          </div>
        </div>
      </Tilt>
    </>
  );
};
