import React, { useEffect, useState, useRef } from "react";
import cn from "classnames";

import "./Logo.scss";

import { Images } from "../../assets/images";
import { useMedia } from "../../assets/hooks/useMedia";

export const Logo = ({ filled, setFilled, logoRef, scrollMouseRef }) => {
  const intervalRef = useRef(null);
  const [fillLogo, setFillLogo] = useState(0);
  const { isMobile } = useMedia();

  const startFilling = () => {
    if (intervalRef.current) return;
    intervalRef.current = setInterval(() => {
      setFillLogo((prevCounter) =>
        prevCounter <= 99.5 ? prevCounter + 0.5 : () => setFilled(true)
      );
    }, 20);
  };

  const stopFilling = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  // stop counter when app is unmounted
  useEffect(() => {
    return () => stopFilling();
  }, []);

  // set whether logo is filled or not
  useEffect(() => {
    if (fillLogo === 100) {
      setFilled(true);
    }
  }, [fillLogo, setFilled]);

  return (
    <div className="logo">
      <div
        className={cn("logo__text", {
          "logo__text--filled": filled,
        })}
        ref={logoRef}
      >
        <span
          className="isHover"
          style={{
            backgroundImage: `linear-gradient(360deg, rgba(251, 249, 235, 1) 0%, rgba(251, 249, 235, 1) ${fillLogo}%, transparent ${fillLogo}%`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          hack24x7
        </span>
      </div>
      <div
        className={cn("logo__pressHold isNotVisible", {
          "logo__pressHold--invisible": filled,
        })}
        onMouseDown={startFilling}
        onTouchStart={startFilling}
        onMouseUp={startFilling}
        onTouchEnd={stopFilling}
        onMouseLeave={stopFilling}
      >
        <div className="logo__pressHold-button">Press &#38;hold</div>
      </div>
      <div
        ref={scrollMouseRef}
        className={cn("logo__scroll isHover", {
          "logo__scroll--visible": filled,
        })}
      >
        {isMobile ? (
          <div className="logo__scroll-box">
            <Images.SvgSwipe />
            Swipe
          </div>
        ) : (
          <div className="logo__scroll-box">
            <Images.SvgMouse />
            scroll
          </div>
        )}
      </div>
    </div>
  );
};
