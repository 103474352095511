export const responsive = [
  {
    breakpoint: 370,
    settings: {
      slidesToShow: 0.5,
    },
  },
  {
    breakpoint: 380,
    settings: {
      slidesToShow: 1.05,
    },
  },
  {
    breakpoint: 390,
    settings: {
      slidesToShow: 1.08,
    },
  },
  {
    breakpoint: 400,
    settings: {
      slidesToShow: 1.11,
    },
  },
  {
    breakpoint: 410,
    settings: {
      slidesToShow: 1.14,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1.17,
    },
  },
  {
    breakpoint: 430,
    settings: {
      slidesToShow: 1.21,
    },
  },
  {
    breakpoint: 440,
    settings: {
      slidesToShow: 1.25,
    },
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1.28,
    },
  },
  {
    breakpoint: 460,
    settings: {
      slidesToShow: 1.31,
    },
  },
  {
    breakpoint: 470,
    settings: {
      slidesToShow: 1.35,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1.38,
    },
  },
  {
    breakpoint: 490,
    settings: {
      slidesToShow: 1.44,
    },
  },
  {
    breakpoint: 500,
    settings: {
      slidesToShow: 1.48,
    },
  },
  {
    breakpoint: 1025,
    settings: {
      slidesToShow: 1.02,
    },
  },
  {
    breakpoint: 1030,
    settings: {
      slidesToShow: 1.03,
    },
  },
  {
    breakpoint: 1040,
    settings: {
      slidesToShow: 1.04,
    },
  },
  {
    breakpoint: 1050,
    settings: {
      slidesToShow: 1.05,
    },
  },
  {
    breakpoint: 1060,
    settings: {
      slidesToShow: 1.065,
    },
  },
  {
    breakpoint: 1070,
    settings: {
      slidesToShow: 1.075,
    },
  },
  {
    breakpoint: 1080,
    settings: {
      slidesToShow: 1.09,
    },
  },
  {
    breakpoint: 1090,
    settings: {
      slidesToShow: 1.1,
    },
  },
  {
    breakpoint: 1100,
    settings: {
      slidesToShow: 1.115,
    },
  },
  {
    breakpoint: 1110,
    settings: {
      slidesToShow: 1.125,
    },
  },
  {
    breakpoint: 1120,
    settings: {
      slidesToShow: 1.14,
    },
  },
  {
    breakpoint: 1130,
    settings: {
      slidesToShow: 1.15,
    },
  },
  {
    breakpoint: 1140,
    settings: {
      slidesToShow: 1.16,
    },
  },
  {
    breakpoint: 1150,
    settings: {
      slidesToShow: 1.177,
    },
  },
  {
    breakpoint: 1160,
    settings: {
      slidesToShow: 1.185,
    },
  },
  {
    breakpoint: 1170,
    settings: {
      slidesToShow: 1.2,
    },
  },
  {
    breakpoint: 1180,
    settings: {
      slidesToShow: 1.215,
    },
  },
  {
    breakpoint: 1190,
    settings: {
      slidesToShow: 1.225,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 1.237,
    },
  },
  {
    breakpoint: 1210,
    settings: {
      slidesToShow: 1.255,
    },
  },
  {
    breakpoint: 1220,
    settings: {
      slidesToShow: 1.265,
    },
  },
  {
    breakpoint: 1230,
    settings: {
      slidesToShow: 1.275,
    },
  },
  {
    breakpoint: 1240,
    settings: {
      slidesToShow: 1.285,
    },
  },
  {
    breakpoint: 1250,
    settings: {
      slidesToShow: 1.295,
    },
  },
  {
    breakpoint: 1260,
    settings: {
      slidesToShow: 1.305,
    },
  },
  {
    breakpoint: 1270,
    settings: {
      slidesToShow: 1.32,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 1.33,
    },
  },
  {
    breakpoint: 1290,
    settings: {
      slidesToShow: 1.35,
    },
  },
  {
    breakpoint: 1300,
    settings: {
      slidesToShow: 1.36,
    },
  },
  {
    breakpoint: 1310,
    settings: {
      slidesToShow: 1.37,
    },
  },
  {
    breakpoint: 1320,
    settings: {
      slidesToShow: 1.385,
    },
  },
  {
    breakpoint: 1330,
    settings: {
      slidesToShow: 1.4,
    },
  },
  {
    breakpoint: 1340,
    settings: {
      slidesToShow: 1.41,
    },
  },
  {
    breakpoint: 1350,
    settings: {
      slidesToShow: 1.42,
    },
  },
  {
    breakpoint: 1360,
    settings: {
      slidesToShow: 1.43,
    },
  },
  {
    breakpoint: 1370,
    settings: {
      slidesToShow: 1.44,
    },
  },
  {
    breakpoint: 1380,
    settings: {
      slidesToShow: 1.46,
    },
  },

  {
    breakpoint: 1390,
    settings: {
      slidesToShow: 1.47,
    },
  },
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 1.48,
    },
  },

  {
    breakpoint: 1410,
    settings: {
      slidesToShow: 1.495,
    },
  },
  {
    breakpoint: 1420,
    settings: {
      slidesToShow: 1.51,
    },
  },
  {
    breakpoint: 1430,
    settings: {
      slidesToShow: 1.525,
    },
  },
  {
    breakpoint: 1440,
    settings: {
      slidesToShow: 1.54,
    },
  },
  {
    breakpoint: 1450,
    settings: {
      slidesToShow: 1.55,
    },
  },
  {
    breakpoint: 1460,
    settings: {
      slidesToShow: 1.56,
    },
  },
  {
    breakpoint: 1470,
    settings: {
      slidesToShow: 1.57,
    },
  },
  {
    breakpoint: 1480,
    settings: {
      slidesToShow: 1.59,
    },
  },
  {
    breakpoint: 1490,
    settings: {
      slidesToShow: 1.6,
    },
  },
  {
    breakpoint: 1500,
    settings: {
      slidesToShow: 1.61,
    },
  },
  {
    breakpoint: 1510,
    settings: {
      slidesToShow: 1.63,
    },
  },
  {
    breakpoint: 1520,
    settings: {
      slidesToShow: 1.64,
    },
  },
  {
    breakpoint: 1530,
    settings: {
      slidesToShow: 1.66,
    },
  },
  {
    breakpoint: 1540,
    settings: {
      slidesToShow: 1.67,
    },
  },
  {
    breakpoint: 1550,
    settings: {
      slidesToShow: 1.68,
    },
  },
  {
    breakpoint: 1560,
    settings: {
      slidesToShow: 1.69,
    },
  },
  {
    breakpoint: 1570,
    settings: {
      slidesToShow: 1.71,
    },
  },
  {
    breakpoint: 1580,
    settings: {
      slidesToShow: 1.72,
    },
  },
  {
    breakpoint: 1590,
    settings: {
      slidesToShow: 1.73,
    },
  },
  {
    breakpoint: 1600,
    settings: {
      slidesToShow: 1.74,
    },
  },
];
