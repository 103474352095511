import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import Slider from "react-slick";

import { Container } from "../Container";
import { TechnologyStack } from "./Stack";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Technology.scss";

import { useMedia } from "../../assets/hooks/useMedia";

export const Technology = () => {
  const { isMobile } = useMedia();
  const [blockType, setBlockType] = useState("experts");
  const [active, setActive] = useState("experts");
  const [addValue, setAddValue] = useState(10);
  const [secondValue, setSecondValue] = useState(10);

  const parentRef = useRef(null);
  const sliderRef = useRef();

  const scroll = (e) => {
    e.preventDefault();
    if (sliderRef === null) return 0;

    e.wheelDelta > 0
      ? sliderRef.current.slickNext()
      : sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const parent = parentRef.current;
    parent.addEventListener("wheel", scroll, true);

    return () => {
      parent.removeEventListener("wheel", scroll, true);
    };
  }, [parentRef]);

  const settings = {
    arrows: false,
    fade: false,
    infinite: false,
    swipe: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: isMobile ? 1 : -1,
    variableWidth: true,
    beforeChange: (current, next) => setSecondValue(next * 20),
  };

  const TOP_BUTTONS = [
    {
      id: "experts",
      title: "Dedicated Experts",
      style: { width: 178 },
    },
    {
      id: "database",
      title: "Database",
      style: { width: 106 },
    },
    {
      id: "testing",
      title: "Testing & Integration",
      style: { width: 195 },
    },
    {
      id: "computing",
      title: "Cloud copmuting",
      style: { width: 161 },
    },
    {
      id: "integration",
      title: "API Integration",
      style: { width: 145 },
    },
    {
      id: "infrastructure",
      title: "Inrastructure & Automatic",
      style: { width: 235 },
    },
    {
      id: "iot",
      title: "IoT",
      style: { width: 55 },
    },
  ];

  return (
    <Container
      title="Technology We have expertise in"
      largeTitle
      technology
      secondValue={secondValue}
    >
      <div className="technology">
        <div className="technology__cards">
          <div className="technology__cards-top isHover" ref={parentRef}>
            <Slider ref={sliderRef} {...settings}>
              {TOP_BUTTONS.map(({ id, title, style }) => (
                <button
                  key={id}
                  onClick={() => {
                    setBlockType(id);
                    setActive(id);
                  }}
                  className={cn("technology__cards-button", {
                    "technology__cards-button--active": active === id,
                  })}
                  style={style}
                >
                  {title}
                </button>
              ))}
            </Slider>
          </div>
          <div className="technology__cards-body">
            <TechnologyStack type={blockType} setAddValue={setAddValue} />
          </div>
        </div>
        {isMobile && blockType !== "database" && (
          <progress
            max="100"
            value={addValue}
            className="technology__progress-layout"
          ></progress>
        )}
      </div>
    </Container>
  );
};
