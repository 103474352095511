import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ServicesForOffer.scss";

import { Card } from "../Card";
import { Container } from "../Container";

import { Images } from "../../assets/images";
import { useMedia } from "../../assets/hooks/useMedia";
import { responsive } from "./settings";

export const ServicesForOffer = () => {
  const parentRef = useRef(null);
  const sliderRef = useRef();
  const { isMobile, isLargeDesktop } = useMedia();
  const [addValue, setAddValue] = useState(10);

  const scroll = (e) => {
    e.preventDefault();
    if (sliderRef === null) return 0;

    e.wheelDelta > 0
      ? sliderRef.current.slickNext()
      : sliderRef.current.slickPrev();
  };

  // cards horizontal scroll animation
  useEffect(() => {
    const parent = parentRef.current;
    parent.addEventListener("wheel", scroll, true);

    return () => {
      parent.removeEventListener("wheel", scroll, true);
    };
  }, [parentRef]);

  const CARD_VALUES = [
    {
      id: 1,
      icon: Images.SvgSoftwareEngineering,
      title: "Software Engineering",
      text: "We will work with you to collect requirements and build a scalable and a managable software in a iterative manner. Our experts with professional background will be able to engineer a precise solution to address your requirements.",
    },
    {
      id: 2,
      icon: Images.SvgITConsultation,
      title: "IT Consultation",
      text: "Our team is well versed with many different aspects of Information Technology services and are able to provide their expert opinion and consult with you to solve your all your IT needs.",
    },
    {
      id: 3,
      icon: Images.SvgTesting,
      title: "QA / Testing",
      text: "Ensure a reliable and high quality product by letting our testers work with your product and find all the issues with it before any user sees them. We can provide an end to end solution to ensure high quality product.",
    },
    {
      id: 4,
      icon: Images.SvgSecurity,
      title: "Automation & Security",
      text: "You never have to repeat yourself with our automation, ensure perfect and reproducible results each and every time. Our in-house security experts can provide insights on wide spectrum of security related best practices and can provide their services on need basis, with our experts on call 24x7, you can have a sound sleep knowing everything is taken care of.",
    },
    {
      id: 5,
      icon: Images.SvgDevelopment,
      title: "Research & Development",
      text: "We provide custom solutions based on your specific needs and our team can explore the market and provide you with research reports and develop necessary solutions with cutting edge technologies.",
    },
    {
      id: 6,
      icon: Images.SvgDesignConsultation,
      title: "UI/UX Consultation",
      text: "With our UI/UX veterans, you literally get what you see. We can add the wow factor to your product and provide a very smooth experience to your users, which will make them revisit you always.",
    },
    {
      id: 7,
      icon: Images.SvgBlockchain,
      title: "Blockchain Services",
      text: "Whether you want to roll out your own cryptocurrency or maintain a provable log of transactions, we can help you get it done with the best of distributed application development on top of various blockchain implementations.",
    },
    {
      id: 8,
      icon: Images.SvgMachineLearning,
      title: "Machine Learning & Artificial Intelligence",
      text: "Recommend your products to your users even before they realize they need them. Our AI/ML researchers can deep dive into your data and come up with custom models that will let you focus on things that matter.",
    },
  ];

  const settings = {
    arrows: false,
    fade: false,
    infinite: false,
    swipe: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: isMobile ? 1 : -1,
    beforeChange: (current, next) => setAddValue(next * 15),
    responsive: responsive,
  };
  const largeDesktopSettings = {
    arrows: false,
    fade: false,
    infinite: false,
    swipe: true,
    speed: 1000,
    slidesToShow: 1,
    variableWidth: true,
    slidesToScroll: -1,
  };

  return (
    <Container title="Services We Offer">
      <div className="services-for-offer">
        <div className="services-for-offer__paragraph isHover">
          <p>
            We take up projects of all kinds of difficulties, whether as simple
            as designing a web presence for you, or as complex as implementing
            the core product for a startup. Our team members shall work with
            individual clients and offer a tailor made solution that fits the
            requirements.
          </p>
        </div>
        <div className="services-for-offer__cards" ref={parentRef}>
          {isLargeDesktop ? (
            <Slider {...largeDesktopSettings} ref={sliderRef}>
              {CARD_VALUES.map(({ icon, title, text, id }) => (
                <Card
                  key={id}
                  variant="service"
                  title={title}
                  Icon={icon}
                  text={text}
                  className="services-for-offer__box"
                />
              ))}
            </Slider>
          ) : (
            <Slider {...settings} ref={sliderRef}>
              {CARD_VALUES.map(({ icon, title, text, id }) => (
                <Card
                  key={id}
                  variant="service"
                  title={title}
                  Icon={icon}
                  text={text}
                  className="services-for-offer__box"
                />
              ))}
            </Slider>
          )}
        </div>
        {isMobile && (
          <progress
            max="100"
            value={addValue}
            className="services-for-offer__progress"
          ></progress>
        )}
      </div>
    </Container>
  );
};
