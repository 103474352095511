import React from "react";

import "./BackgroundText.scss";

export const BackgroundText = () => {
  return (
    <ul className="background-text">
      <li className="background-text__item">we treat</li>
      <li className="background-text__item">your business</li>
      <li className="background-text__item">like it's</li>
      <li className="background-text__item">our business</li>
    </ul>
  );
};
