import React, { useEffect, useRef } from "react";
import Tilt from "react-parallax-tilt";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useTypewriter } from "react-simple-typewriter";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./Service.scss";

import { Container } from "../Container";

import { Images } from "../../assets/images";
import { useMedia } from "../../assets/hooks/useMedia";

gsap.registerPlugin(ScrollTrigger);

export const Service = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const codesRef = useRef(null);
  const itemsRef = useRef(null);
  const { isMobile } = useMedia();

  const codeString = `const toggleClasses = classNames({
      [styles.Base]: true,
      [styles[‘Base--isOpen’]]: isOpen,
      [styles[‘Base--isDisabled’]]: isDisabled || items.length ===0
  });

  return (
    <div className={toggleClasses} ref=”selector” onMouseDown={this.handleClasses}>
        <div className={styles.Label} onMouseUp={this.toggleSelect}>
            { currentFont || labelValue }
        </div>
        { this.state.isOpen &&
          <ClickOutHandler onClickOut={this.onClickOut}>
              <div className={styles.ScrollArea}>
                  <ul className={styles.List}>
                      { items && items.map((item, index) => {`;
  const { text } = useTypewriter({
    words: [codeString],
    loop: true,
    typeSpeed: 100,
    deleteSpeed: 0.001,
  });
  const desktopStyles = {
    background: "rgba(60, 60, 60, 0.8)",
    borderRadius: "16px",
    border: "2px solid rgba(255, 255, 255, 0.4)",
    boxShadow: "none",
    width: "712px",
    height: "488px",
    backdropFilter: "blur(24px)",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "26px",
    margin: "0",
    padding: "24px",
  };
  const mobileStyles = {
    background: 'transparent',
    border: "none",
    textShadow: "none",
    boxShadow: "none",
    width: "712px",
    height: "440px",
    fontSize: "16px",
    lineHeight: "26px",
    fontWeight: "400",
    margin: "0",
    padding: "0",
  };

  // we serve items scroll animation
  useEffect(() => {
    const about = document?.querySelector(".menu__option-wrapper--about");

    const timeLine = gsap.timeline({
      scrollTrigger: {
        trigger: "#service",
        start: "top top",
        end: "+=1800",
        scrub: 1,
        onEnter: () => {
          about?.classList.add("active");
        },
        onLeave: () => {
          about?.classList.remove("active");
        },
        onEnterBack: () => {
          about?.classList.add("active");
        },
      },
    });

    timeLine
      .fromTo(
        ref1.current,
        { autoAlpha: 0, yPercent: 200 },
        { autoAlpha: 1, yPercent: 0 }
      )
      .fromTo(
        ref2.current,
        { autoAlpha: 0, yPercent: 200 },
        { autoAlpha: 1, yPercent: 0 }
      )
      .fromTo(
        ref3.current,
        { autoAlpha: 0, yPercent: 200 },
        { autoAlpha: 1, yPercent: 0 }
      )
      .fromTo(
        ref4.current,
        { autoAlpha: 0, yPercent: 200 },
        { autoAlpha: 1, yPercent: 0 }
      )
      .fromTo(
        ref5.current,
        { autoAlpha: 0, yPercent: 200 },
        { autoAlpha: 1, yPercent: 0 }
      )
      .fromTo(
        ref6.current,
        { autoAlpha: 0, yPercent: 200 },
        { autoAlpha: 1, yPercent: 0 }
      );

    if (isMobile) {
      const mobileTimeline = gsap.timeline({
        scrollTrigger: {
          trigger: "#service",
          start: "top -1900",
          end: "+=600",
          scrub: 1,
        },
      });

      mobileTimeline
        .to(itemsRef.current, {
          autoAlpha: 0,
          display: "none",
        })
        .fromTo(
          codesRef.current,
          {
            y: 500,
          },
          {
            y: 0,
          }
        );
    }
  }, [isMobile]);

  const SERVICE_TYPES = [
    { id: "#id1", ref: ref1, name: "Software Product teams of any size" },
    { id: "#id2", ref: ref2, name: "Tech Startups" },
    { id: "#id3", ref: ref3, name: "Enterprise Clients" },
    { id: "#id4", ref: ref4, name: "IaaS, PaaS & SaaS providers" },
    { id: "#id5", ref: ref5, name: "Mobile Application / Game Developers" },
    { id: "#id6", ref: ref6, name: "Marketing" },
  ];

  return (
    <Container title="We serve">
      <div className="service">
        <div className="service__paragraph isHover">
          <p>
            Enabling businesses to turn their idea into reality. High paced
            execution, fast iterations, and delivery on time, every time.
          </p>
          <ul ref={itemsRef}>
            {SERVICE_TYPES.map(({ name, id, ref }) => (
              <li key={id} ref={ref}>
                <Images.SvgApprove />
                {name}
              </li>
            ))}
          </ul>
        </div>
        <div className="service__codes" ref={codesRef}>
          {isMobile ? (
            <SyntaxHighlighter
              language="jsx"
              style={dark}
              customStyle={isMobile ? mobileStyles : desktopStyles}
              wrapLongLines={true}
            >
              {text}
            </SyntaxHighlighter>
          ) : (
            <Tilt transitionSpeed={50} tiltMaxAngleX={1} tiltMaxAngleY={1}>
              <SyntaxHighlighter
                language="jsx"
                style={dark}
                customStyle={isMobile ? mobileStyles : desktopStyles}
                wrapLongLines={true}
              >
                {text}
              </SyntaxHighlighter>
            </Tilt>
          )}
        </div>
      </div>
    </Container>
  );
};
