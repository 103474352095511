import React from "react";
import cn from "classnames";

import "./Menu.scss";

const MENU_OPTIONS = [
  {
    path: "#home",
    name: "Home",
    num: "01",
    key: "home",
  },
  {
    path: "#key",
    name: "About",
    num: "02",
    key: "about",
  },
  {
    path: "#offer",
    name: "services",
    num: "03",
    key: "service",
  },
  {
    path: "#process",
    name: "process",
    num: "04",
    key: "process",
  },
  {
    path: "#technology",
    name: "technology",
    num: "05",
    key: "technology",
  },
];

export const Menu = ({ menuPageRef, isVisible, menusRefs, menuClick }) => {
  return (
    <div
      className={cn("menu", {
        "menu--visible": isVisible,
      })}
      ref={menuPageRef}
    >
      <div className="menu__wrapper isHover">
        {MENU_OPTIONS.map(({ path, num, name, key }) => (
          <div
            className={`menu__option-wrapper menu__option-wrapper--${key}`}
            key={key}
            onClick={menuClick}
            ref={menusRefs}
          >
            <span className="menu__option--color">{num}</span>
            <a
              href={path}
              className={cn("menu__option", {
                "menu__option menu__item": path !== "#home",
              })}
            >
              {name}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};
