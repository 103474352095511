import React from "react";
import cn from "classnames";

import "./Container.scss";

import { useMedia } from "../../assets/hooks/useMedia";

export const Container = ({
  title,
  children,
  contact,
  technology,
  secondValue,
}) => {
  const { isMobile } = useMedia();

  return (
    <div className={cn("container", { "container--contact": contact })}>
      <div className="container__title isHover">{title}</div>
      <div className="container__body">{children}</div>
      {technology && isMobile && (
        <progress
          max="100"
          value={secondValue}
          className="container__technology-progress"
        ></progress>
      )}
    </div>
  );
};
