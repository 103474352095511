import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { Card } from "../Card";
import { Container } from "../Container";

import "./Process.scss";

import { Images } from "../../assets/images";
import { useMedia } from "../../assets/hooks/useMedia";

gsap.registerPlugin(ScrollTrigger);

export const Process = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const { isMobile } = useMedia();

  const CARD_VALUES = [
    {
      id: 1,
      icon: Images.SvgDedicatedExperts,
      title: "Dedicated Experts",
      text: "Our team has specialists as well as generalists. Specialists can deep dive and customize every aspect of the solution we provide. Generalists being expert in multiple domains can bring in unique perspective and knowledge across diverse industries to provide the best possible solution for any given problem.",
      style: { top: "-48px", left: 0, width: 614, height: 290 },
      mobileStyle: { top: "-48px", left: 0 },
      ref: ref1,
    },
    {
      id: 2,
      icon: Images.SvgTurnkeySolutions,
      title: "Turnkey Solutions",
      text: "We shall gather requirements and develop a custom solution which will be entirely managed in a hands-off way by your local technical team. Any updates or maintenance will be done by us on an ongoing basis.",
      style: { top: "-48px", right: 0, width: 594, height: 290 },
      mobileStyle: { top: "-24px", right: 0 },
      ref: ref2,
    },
    {
      id: 3,
      icon: Images.SvgExtendTeam,
      title: "Extend Your Team",
      text: "Find a hacker who is a right match based on your technical requirements to work alongside your team as part of your team, bringing in deep knowledge, precise engineering process and expertise of hack24x7.",
      style: { top: 0, left: 0, width: 614, height: 250 },
      mobileStyle: { top: 0, left: 0 },
      ref: ref3,
    },
    {
      id: 4,
      icon: Images.SvgHandshake,
      title: "Advisory Services",
      text: "hack24x7 experts can assess, strategize and devise a plan and provide the necessary help in different verticals of the client’s business. Including Resource Management, Strategic Sourcing,",
      style: { top: 0, right: 0, width: 594, height: 250 },
      mobileStyle: { top: "24px", right: 0 },
      ref: ref4,
    },
  ];

  // process cards scroll animation
  useEffect(() => {
    const process = document?.querySelector(".menu__option-wrapper--process");

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: "#process",
        start: "top top",
        end: "+=1800",
        scrub: 1,
        onEnter: () => {
          process?.classList.add("active");
        },
        onLeave: () => {
          process?.classList.remove("active");
        },
        onEnterBack: () => {
          process?.classList.add("active");
        },
      },
    });

    if (isMobile) {
      gsap.set("#num11", { autoAlpha: 1, y: 0 });
      gsap.set("#num22", { autoAlpha: 0, y: 10 });
      gsap.set("#num33", { autoAlpha: 0, y: 10 });
      gsap.set("#num44", { autoAlpha: 0, y: 10 });

      timeline
        .fromTo(
          ref1.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        )
        .to("#num11", { autoAlpha: 0, y: -10 })
        .to("#num22", { autoAlpha: 1, y: 0 })
        .fromTo(
          ref2.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        )
        .to("#num22", { autoAlpha: 0, y: -10 })
        .to("#num33", { autoAlpha: 1, y: 0 })
        .fromTo(
          ref3.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        )
        .to("#num33", { autoAlpha: 0, y: -10 })
        .to("#num44", { autoAlpha: 1, y: 0 })
        .fromTo(
          ref4.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        );
    } else {
      timeline
        .fromTo(
          ref1.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        )
        .fromTo(
          ref2.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        )
        .fromTo(
          ref3.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        )
        .fromTo(
          ref4.current,
          {
            yPercent: 200,
          },
          {
            yPercent: 0,
          }
        );
    }
  }, [isMobile]);

  return (
    <Container title="Process We Follow">
      <div className="process">
        <div className="process__cards">
          {CARD_VALUES.map(
            ({ icon, title, text, id, ref, style, mobileStyle }) => (
              <div className="process__box" key={id}>
                <Card
                  variant="process"
                  title={title}
                  Icon={icon}
                  text={text}
                  cardStyle={isMobile ? mobileStyle : style}
                  cardRef={ref}
                />
              </div>
            )
          )}
        </div>
        {isMobile && (
          <div className="process__counter">
            <div className="process__counter-box">
              <div id="num11">1</div>
              <div id="num22">2</div>
              <div id="num33">3</div>
              <div id="num44">4</div>
            </div>
            <span className="process__counter--all">/4</span>
          </div>
        )}
      </div>
    </Container>
  );
};
