import { ReactComponent as SvgApprove } from "./approve.svg";
import { ReactComponent as SvgAttach } from "./attach.svg";
import { ReactComponent as SvgBlockchain } from "./blockchain.svg";
import { ReactComponent as SvgCostEffectiveSolution } from "./cost-effective-solution.svg";
import { ReactComponent as SvgContact } from "./contact.svg";
import { ReactComponent as SvgCross } from "./cross.svg";
import { ReactComponent as SvgDedicatedExperts } from "./dedicated-experts.svg";
import { ReactComponent as SvgDesignConsultation } from "./design-consultation.svg";
import { ReactComponent as SvgDevelopment } from "./development.svg";
import { ReactComponent as SvgEmail } from "./email.svg";
import { ReactComponent as SvgExtendTeam } from "./extend-team.svg";
import { ReactComponent as SvgHandshake } from "./handshake.svg";
import { ReactComponent as SvgImpressiveCommunication } from "./impressive-communication.svg";
import { ReactComponent as SvgInteractiveDesign } from "./interactive-design.svg";
import { ReactComponent as SvgITConsultation } from "./IT-consultation.svg";
import { ReactComponent as SvgLogo } from "./logo.svg";
import { ReactComponent as SvgMachineLearning } from "./machine-learning.svg";
import { ReactComponent as SvgMap } from "./map.svg";
import { ReactComponent as SvgMenuHamburger } from "./menu-hamburger.svg";
import { ReactComponent as SvgMouse } from "./mouse.svg";
import { ReactComponent as SvgPromptDelivery } from "./prompt-delivery.svg";
import { ReactComponent as SvgReliableSolution } from "./reliable-solution.svg";
import { ReactComponent as SvgSecurity } from "./security.svg";
import { ReactComponent as SvgServiceQuality } from "./service-quality.svg";
import { ReactComponent as SvgSoftwareEngineering } from "./software-engineering.svg";
import { ReactComponent as SvgSwipe } from "./swipe.svg";
import { ReactComponent as SvgTesting } from "./testing.svg";
import { ReactComponent as SvgTurnkeySolutions } from "./turnkey-solutions.svg";

export const Images = {
  SvgApprove,
  SvgAttach,
  SvgBlockchain,
  SvgCostEffectiveSolution,
  SvgContact,
  SvgCross,
  SvgDedicatedExperts,
  SvgDesignConsultation,
  SvgDevelopment,
  SvgEmail,
  SvgExtendTeam,
  SvgHandshake,
  SvgImpressiveCommunication,
  SvgInteractiveDesign,
  SvgITConsultation,
  SvgLogo,
  SvgMachineLearning,
  SvgMap,
  SvgMenuHamburger,
  SvgMouse,
  SvgPromptDelivery,
  SvgReliableSolution,
  SvgSecurity,
  SvgServiceQuality,
  SvgSoftwareEngineering,
  SvgSwipe,
  SvgTesting,
  SvgTurnkeySolutions,
};
