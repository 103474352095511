import React, { useRef, useEffect } from "react";
import Slider from "react-slick";
import cn from "classnames";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TechnologyStack.scss";

import { Card } from "../../Card";

import {
  expertsData,
  databseData,
  testingData,
  computingData,
  infrastructureData,
  integrationData,
  iotData,
} from "./technologiesData";
import { responsive } from "./settings";

import { useMedia } from "../../../assets/hooks/useMedia";

export const TechnologyStack = ({ type, setAddValue }) => {
  const parentRef = useRef(null);
  const sliderRef = useRef();
  const { isMobile, isLargeDesktop } = useMedia();

  const scroll = (e) => {
    e.preventDefault();
    if (sliderRef === null) return 0;

    e.wheelDelta > 0
      ? sliderRef.current.slickNext()
      : sliderRef.current.slickPrev();
  };

  // horizontal scroll
  useEffect(() => {
    const parent = parentRef.current;
    parent.addEventListener("wheel", scroll, true);

    return () => {
      parent.removeEventListener("wheel", scroll, true);
    };
  }, [parentRef]);

  const PROGRESS_BAR_VALUE =
    type === "experts" || type === "iot"
      ? 30
      : type === "infrastructure"
      ? 40
      : 160;

  const settings = {
    arrows: false,
    fade: false,
    infinite: false,
    swipe: true,
    speed: 700,
    slidesToShow: 9,
    rows:
      !isMobile &&
      (type === "iot" || type === "infrastructure" || type === "experts")
        ? 2
        : !isMobile &&
          (type !== "iot" || type !== "infrastructure" || type !== "experts")
        ? 1
        : isMobile && type !== "iot" && type !== "infrastructure"
        ? 4
        : 3,
    slidesToScroll: isMobile ? 1 : -1,
    beforeChange: (current, next) => setAddValue(next * PROGRESS_BAR_VALUE),
    responsive: responsive,
  };
  const largeDesktopSettings = {
    arrows: false,
    fade: false,
    infinite: false,
    swipe: true,
    speed: 700,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: -1,
  };

  return (
    <div
      className={cn("technology-stack", {
        "technology-stack--database": type === "database",
      })}
    >
      <div className="technology-stack__container" ref={parentRef}>
        {isLargeDesktop ? (
          <Slider {...largeDesktopSettings} ref={sliderRef}>
            {type === "experts" &&
              expertsData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}

            {type === "database" &&
              databseData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "testing" &&
              testingData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "computing" &&
              computingData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "integration" &&
              integrationData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "infrastructure" &&
              infrastructureData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "iot" &&
              iotData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
          </Slider>
        ) : (
          <Slider {...settings} ref={sliderRef}>
            {type === "experts" &&
              expertsData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}

            {type === "database" &&
              databseData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "testing" &&
              testingData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "computing" &&
              computingData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "integration" &&
              integrationData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "infrastructure" &&
              infrastructureData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
            {type === "iot" &&
              iotData.map(({ id, name, icon }) => (
                <Card key={id} title={name} Icon={icon} variant="technology" />
              ))}
          </Slider>
        )}
      </div>
    </div>
  );
};
