import { useMediaQuery } from "react-responsive";

const MOBILE_RESOLUTION = 744;
const LARGE_DESKTOP_RESOLUTION = 1600;

export const useMedia = () => {
  const isMobile = useMediaQuery({ maxWidth: MOBILE_RESOLUTION });
  const isLargeDesktop = useMediaQuery({ minWidth: LARGE_DESKTOP_RESOLUTION });

  return { isMobile, isLargeDesktop };
};
