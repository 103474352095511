import { ReactComponent as SvgAlexa } from "./alexa.svg";
import { ReactComponent as SvgAmazon } from "./amazon.svg";
import { ReactComponent as SvgAmazonaws } from "./amazon-aws.svg";
import { ReactComponent as SvgAngular } from "./angular.svg";
import { ReactComponent as AvgArduino } from "./arduino.svg";
import { ReactComponent as SvgBeagleBone } from "./beaglebone.svg";
import { ReactComponent as SVgBiometric } from "./biometric.svg";
import { ReactComponent as SvgCassandra } from "./cassandra.svg";
import { ReactComponent as SvgCdn } from "./cdn.svg";
import { ReactComponent as SvgChromePlugin } from "./chrome-plugin.svg";
import { ReactComponent as SvgCircle } from "./circle.svg";
import { ReactComponent as SvgConcourse } from "./concourse.svg";
import { ReactComponent as SvgCouchbase } from "./couchbase.svg";
import { ReactComponent as SvgCss } from "./css.svg";
import { ReactComponent as SvgDjango } from "./django.svg";
import { ReactComponent as SvgDns } from "./dns.svg";
import { ReactComponent as SvgDocker } from "./docker.svg";
import { ReactComponent as SvgDoorLock } from "./door-lock.svg";
import { ReactComponent as SvgElectroLock } from "./electr-locks.svg";
import { ReactComponent as SvgFacebookApi } from "./facebook-api.svg";
import { ReactComponent as SvgFirebase } from "./firebase.svg";
import { ReactComponent as SvgFirewall } from "./firewall.svg";
import { ReactComponent as SvgFlask } from "./flask.svg";
import { ReactComponent as SvgGoogleApi } from "./google-api.svg";
import { ReactComponent as SvgGoogleCloud } from "./google-cloud.svg";
import { ReactComponent as SvgHaskell } from "./haskell.svg";
import { ReactComponent as SvgHomeAutomation } from "./home-automation.svg";
import { ReactComponent as SvgHtml } from "./html.svg";
import { ReactComponent as SvgHumSensor } from "./hum-sensor.svg";
import { ReactComponent as SvgIfttt } from "./ifttt.svg";
import { ReactComponent as SvgJava } from "./java.svg";
import { ReactComponent as SvgJavascript } from "./javascript.svg";
import { ReactComponent as SvgJenkins } from "./jenkins.svg";
import { ReactComponent as SvgKafka } from "./kafka.svg";
import { ReactComponent as SvgKarma } from "./karma.svg";
import { ReactComponent as SvgKubernetes } from "./kubernetes.svg";
import { ReactComponent as SvgLinux } from "./linux.svg";
import { ReactComponent as SvgMaliman } from "./maliman.svg";
import { ReactComponent as SvgMysql } from "./mysql.svg";
import { ReactComponent as SVgNode } from "./node.svg";
import { ReactComponent as SVgOmega } from "./omega.svg";
import { ReactComponent as SvgPhp } from "./php.svg";
import { ReactComponent as SvgPostfix } from "./postfix.svg";
import { ReactComponent as SvgPostgress } from "./postgress.svg";
import { ReactComponent as SvgPython } from "./python.svg";
import { ReactComponent as SvgRaspberry } from "./raspberry.svg";
import { ReactComponent as SvgReact } from "./react.svg";
import { ReactComponent as SvgRemoteMonitoring } from "./remote-monitoring.svg";
import { ReactComponent as SvgRuby } from "./ruby.svg";
import { ReactComponent as SvgSass } from "./sass.svg";
import { ReactComponent as SvgScala } from "./scala.svg";
import { ReactComponent as SvgShell } from "./shell.svg";
import { ReactComponent as SvgSmartCard } from "./smart-card.svg";
import { ReactComponent as SvgSoftwareNetworking } from "./software-networking.svg";
import { ReactComponent as SvgSpinnaker } from "./spinnaker.svg";
import { ReactComponent as SvgSpring } from "./spring.svg";
import { ReactComponent as SvgTempSensor } from "./temp-sensor.svg";
import { ReactComponent as SvgTravis } from "./travis.svg";
import { ReactComponent as SvgTwitterApi } from "./twitter-api.svg";
import { ReactComponent as SvgTypescript } from "./typescript.svg";
import { ReactComponent as SvgVirtualBox } from "./virtual-box.svg";
import { ReactComponent as SvgVmware } from "./vmware.svg";
import { ReactComponent as SvgZapier } from "./zapier.svg";
import { ReactComponent as SvgZero } from "./zero.svg";

export const StackImages = {
  SvgAlexa,
  SvgAmazonaws,
  SvgAmazon,
  SvgAngular,
  AvgArduino,
  SvgBeagleBone,
  SVgBiometric,
  SvgCassandra,
  SvgCdn,
  SvgChromePlugin,
  SvgCircle,
  SvgConcourse,
  SvgCouchbase,
  SvgCss,
  SvgDjango,
  SvgDns,
  SvgDocker,
  SvgDoorLock,
  SvgElectroLock,
  SvgFacebookApi,
  SvgFirebase,
  SvgFirewall,
  SvgFlask,
  SvgGoogleApi,
  SvgGoogleCloud,
  SvgHaskell,
  SvgHomeAutomation,
  SvgHtml,
  SvgHumSensor,
  SvgIfttt,
  SvgJava,
  SvgJavascript,
  SvgJenkins,
  SvgKafka,
  SvgKarma,
  SvgKubernetes,
  SvgLinux,
  SvgMaliman,
  SvgMysql,
  SVgNode,
  SVgOmega,
  SvgPhp,
  SvgPostfix,
  SvgPostgress,
  SvgPython,
  SvgRaspberry,
  SvgReact,
  SvgRemoteMonitoring,
  SvgRuby,
  SvgSass,
  SvgScala,
  SvgShell,
  SvgSmartCard,
  SvgSoftwareNetworking,
  SvgSpinnaker,
  SvgSpring,
  SvgTempSensor,
  SvgTravis,
  SvgTwitterApi,
  SvgTypescript,
  SvgVirtualBox,
  SvgVmware,
  SvgZapier,
  SvgZero,
};
