import React, { useEffect } from "react";
import imagesLoaded from "imagesloaded";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./Loader.scss";

gsap.registerPlugin(ScrollTrigger);

export const Loader = () => {
  // loader animation
  useEffect(() => {
    const images = gsap.utils.toArray("img");
    const loader = document.querySelector(".loader__text--process");
    const updateProgress = (instance) =>
      (loader.textContent = `${Math.round(
        (instance.progressedCount * 100) / images.length
      )}%`);

    const loaderProgress = () => {
      gsap.to(document.querySelector(".loader"), {
        autoAlpha: 0,
        display: "none",
      });
    };

    imagesLoaded(images)
      .on("progress", updateProgress)
      .on("always", loaderProgress);
  });

  return (
    <>
      <div className="loader">
        <div className="loader__text">
          <span className="loader__text--title">hack24x7</span>
          <span className="loader__text--process">0%</span>
        </div>
      </div>
      {/* fake images data to load page */}
      <div className="loader__fake-data">
        <section className="demo-gallery">
          <ul className="wrapper">
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=93"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=42"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=128"
                width="1240"
                alt="imagesHere"
              />
            </li>
          </ul>
        </section>
        <section className="demo-gallery">
          <ul className="wrapper">
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=38"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=34"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=86"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=64"
                width="1240"
                alt="imagesHere"
              />
            </li>
          </ul>
        </section>
        <section className="demo-gallery">
          <ul className="wrapper">
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=59"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=149"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=160"
                width="1240"
                alt="imagesHere"
              />
            </li>
            <li>
              <img
                height="874"
                src="https://source.unsplash.com/random/1240x874?sig=8"
                width="1240"
                alt="imagesHere"
              />
            </li>
          </ul>
        </section>
      </div>
    </>
  );
};
