export const responsive = [
  {
    breakpoint: 320,
    settings: {
      slidesToShow: 1,
    },
  },
  {
    breakpoint: 340,
    settings: {
      slidesToShow: 1,
    },
  },
  {
    breakpoint: 350,
    settings: {
      slidesToShow: 1.05,
    },
  },
  {
    breakpoint: 360,
    settings: {
      slidesToShow: 1.12,
    },
  },
  {
    breakpoint: 370,
    settings: {
      slidesToShow: 1.18,
    },
  },
  {
    breakpoint: 380,
    settings: {
      slidesToShow: 1.23,
    },
  },
  {
    breakpoint: 390,
    settings: {
      slidesToShow: 1.28,
    },
  },
  {
    breakpoint: 400,
    settings: {
      slidesToShow: 1.35,
    },
  },
  {
    breakpoint: 410,
    settings: {
      slidesToShow: 1.4,
    },
  },
  {
    breakpoint: 420,
    settings: {
      slidesToShow: 1.45,
    },
  },
  {
    breakpoint: 430,
    settings: {
      slidesToShow: 1.5,
    },
  },
  {
    breakpoint: 440,
    settings: {
      slidesToShow: 1.55,
    },
  },
  {
    breakpoint: 450,
    settings: {
      slidesToShow: 1.6,
    },
  },
  {
    breakpoint: 460,
    settings: {
      slidesToShow: 1.65,
    },
  },
  {
    breakpoint: 470,
    settings: {
      slidesToShow: 1.7,
    },
  },
  {
    breakpoint: 485,
    settings: {
      slidesToShow: 1.8,
    },
  },
  {
    breakpoint: 490,
    settings: {
      slidesToShow: 1.84,
    },
  },
  {
    breakpoint: 510,
    settings: {
      slidesToShow: 1.9,
    },
  },
  {
    breakpoint: 520,
    settings: {
      slidesToShow: 1.95,
    },
  },
  {
    breakpoint: 530,
    settings: {
      slidesToShow: 2,
    },
  },
  {
    breakpoint: 545,
    settings: {
      slidesToShow: 2.1,
    },
  },
  {
    breakpoint: 555,
    settings: {
      slidesToShow: 2.15,
    },
  },
  {
    breakpoint: 560,
    settings: {
      slidesToShow: 2.2,
    },
  },
  {
    breakpoint: 570,
    settings: {
      slidesToShow: 2.2,
    },
  },
  {
    breakpoint: 580,
    settings: {
      slidesToShow: 2.3,
    },
  },
  {
    breakpoint: 585,
    settings: {
      slidesToShow: 2.35,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: 2.4,
    },
  },
  {
    breakpoint: 995,
    settings: {
      slidesToShow: 3.75,
    },
  },
  {
    breakpoint: 1010,
    settings: {
      slidesToShow: 3.8,
    },
  },
  {
    breakpoint: 1025,
    settings: {
      slidesToShow: 3.85,
    },
  },
  {
    breakpoint: 1040,
    settings: {
      slidesToShow: 4,
    },
  },
  {
    breakpoint: 1055,
    settings: {
      slidesToShow: 4.05,
    },
  },
  {
    breakpoint: 1070,
    settings: {
      slidesToShow: 4.1,
    },
  },
  {
    breakpoint: 1085,
    settings: {
      slidesToShow: 4.15,
    },
  },
  {
    breakpoint: 1100,
    settings: {
      slidesToShow: 4.2,
    },
  },
  {
    breakpoint: 1115,
    settings: {
      slidesToShow: 4.25,
    },
  },
  {
    breakpoint: 1130,
    settings: {
      slidesToShow: 4.35,
    },
  },
  {
    breakpoint: 1145,
    settings: {
      slidesToShow: 4.5,
    },
  },
  {
    breakpoint: 1160,
    settings: {
      slidesToShow: 4.55,
    },
  },
  {
    breakpoint: 1175,
    settings: {
      slidesToShow: 4.6,
    },
  },
  {
    breakpoint: 1190,
    settings: {
      slidesToShow: 4.65,
    },
  },

  {
    breakpoint: 1205,
    settings: {
      slidesToShow: 4.7,
    },
  },
  {
    breakpoint: 1220,
    settings: {
      slidesToShow: 4.85,
    },
  },
  {
    breakpoint: 1235,
    settings: {
      slidesToShow: 4.9,
    },
  },
  {
    breakpoint: 1250,
    settings: {
      slidesToShow: 4.95,
    },
  },
  {
    breakpoint: 1265,
    settings: {
      slidesToShow: 5,
    },
  },
  {
    breakpoint: 1280,
    settings: {
      slidesToShow: 5.1,
    },
  },
  {
    breakpoint: 1295,
    settings: {
      slidesToShow: 5.15,
    },
  },
  {
    breakpoint: 1310,
    settings: {
      slidesToShow: 5.3,
    },
  },
  {
    breakpoint: 1325,
    settings: {
      slidesToShow: 5.45,
    },
  },
  {
    breakpoint: 1340,
    settings: {
      slidesToShow: 5.5,
    },
  },
  {
    breakpoint: 1355,
    settings: {
      slidesToShow: 5.55,
    },
  },
  {
    breakpoint: 1370,
    settings: {
      slidesToShow: 5.6,
    },
  },
  {
    breakpoint: 1385,
    settings: {
      slidesToShow: 5.65,
    },
  },
  {
    breakpoint: 1400,
    settings: {
      slidesToShow: 5.8,
    },
  },
  {
    breakpoint: 1415,
    settings: {
      slidesToShow: 5.85,
    },
  },
  {
    breakpoint: 1430,
    settings: {
      slidesToShow: 5.9,
    },
  },
  {
    breakpoint: 1460,
    settings: {
      slidesToShow: 6.05,
    },
  },
  {
    breakpoint: 1475,
    settings: {
      slidesToShow: 6.15,
    },
  },
  {
    breakpoint: 1490,
    settings: {
      slidesToShow: 6.25,
    },
  },
  {
    breakpoint: 1505,
    settings: {
      slidesToShow: 6.3,
    },
  },
  {
    breakpoint: 1520,
    settings: {
      slidesToShow: 6.35,
    },
  },
  {
    breakpoint: 1535,
    settings: {
      slidesToShow: 6.4,
    },
  },
  {
    breakpoint: 1550,
    settings: {
      slidesToShow: 6.45,
    },
  },
  {
    breakpoint: 1565,
    settings: {
      slidesToShow: 6.65,
    },
  },
  {
    breakpoint: 1580,
    settings: {
      slidesToShow: 6.75,
    },
  },
  {
    breakpoint: 1595,
    settings: {
      slidesToShow: 6.80,
    },
  },
  {
    breakpoint: 1610,
    settings: {
      slidesToShow: 6.85,
    },
  },
  // {
  //   breakpoint: 1625,
  //   settings: {
  //     slidesToShow: 6.9,
  //   },
  // },
  // {
  //   breakpoint: 1640,
  //   settings: {
  //     slidesToShow: 6.95,
  //   },
  // },
  // {
  //   breakpoint: 1655,
  //   settings: {
  //     slidesToShow: 7,
  //   },
  // },
  // {
  //   breakpoint: 1670,
  //   settings: {
  //     slidesToShow: 7.15,
  //   },
  // },
  // {
  //   breakpoint: 1685,
  //   settings: {
  //     slidesToShow: 7.25,
  //   },
  // },
  // {
  //   breakpoint: 1700,
  //   settings: {
  //     slidesToShow: 7.35,
  //   },
  // },
  // {
  //   breakpoint: 1715,
  //   settings: {
  //     slidesToShow: 7.4,
  //   },
  // },
  // {
  //   breakpoint: 1730,
  //   settings: {
  //     slidesToShow: 7.45,
  //   },
  // },
  // {
  //   breakpoint: 1745,
  //   settings: {
  //     slidesToShow: 7.5,
  //   },
  // },
  // {
  //   breakpoint: 1760,
  //   settings: {
  //     slidesToShow: 7.55,
  //   },
  // },
  // {
  //   breakpoint: 1775,
  //   settings: {
  //     slidesToShow: 7.6,
  //   },
  // },
  // {
  //   breakpoint: 1790,
  //   settings: {
  //     slidesToShow: 7.65,
  //   },
  // },
  // {
  //   breakpoint: 1805,
  //   settings: {
  //     slidesToShow: 7.9,
  //   },
  // },
  // {
  //   breakpoint: 1820,
  //   settings: {
  //     slidesToShow: 8,
  //   },
  // },
  // {
  //   breakpoint: 1835,
  //   settings: {
  //     slidesToShow: 8.1,
  //   },
  // },
  // {
  //   breakpoint: 1850,
  //   settings: {
  //     slidesToShow: 8.2,
  //   },
  // },
  // {
  //   breakpoint: 1865,
  //   settings: {
  //     slidesToShow: 8.3,
  //   },
  // },
  // {
  //   breakpoint: 1880,
  //   settings: {
  //     slidesToShow: 8.4,
  //   },
  // },
  // {
  //   breakpoint: 1895,
  //   settings: {
  //     slidesToShow: 8.5,
  //   },
  // },
  // {
  //   breakpoint: 1910,
  //   settings: {
  //     slidesToShow: 8.55,
  //   },
  // },
  // {
  //   breakpoint: 1925,
  //   settings: {
  //     slidesToShow: 8.6,
  //   },
  // },
  // {
  //   breakpoint: 1940,
  //   settings: {
  //     slidesToShow: 8.65,
  //   },
  // },
  // {
  //   breakpoint: 1955,
  //   settings: {
  //     slidesToShow: 8.7,
  //   },
  // },
  // {
  //   breakpoint: 1970,
  //   settings: {
  //     slidesToShow: 8.75,
  //   },
  // },
  // {
  //   breakpoint: 1985,
  //   settings: {
  //     slidesToShow: 8.8,
  //   },
  // },
  // {
  //   breakpoint: 2000,
  //   settings: {
  //     slidesToShow: 8.85,
  //   },
  // },
//   {
//     breakpoint: 1340,
//     settings: {
//       slidesToShow: 5.5,
//     },
//   },
//   {
//     breakpoint: 1340,
//     settings: {
//       slidesToShow: 5.5,
//     },
//   },
//   {
//     breakpoint: 1340,
//     settings: {
//       slidesToShow: 5.5,
//     },
//   },
//   {
//     breakpoint: 1340,
//     settings: {
//       slidesToShow: 5.5,
//     },
//   },
//   {
//     breakpoint: 1340,
//     settings: {
//       slidesToShow: 5.5,
//     },
//   },
//   {
//     breakpoint: 1340,
//     settings: {
//       slidesToShow: 5.5,
//     },
//   },
];
