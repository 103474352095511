import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import { gsap } from "gsap";

import "./Header.scss";

import { Menu } from "../Menu";

import { Images } from "../../assets/images";
import { useMedia } from "../../assets/hooks/useMedia";

export const Header = ({ filled, headerMenusRef, headerHamburgerRef }) => {
  const menuRef = useRef();
  const menusRefs = useRef();
  const menuPageRef = useRef(null);
  const [isVisibleMenu, setIsVisibleMenu] = useState(false);
  const { isMobile } = useMedia();

  menusRefs.current = [];

  // hamburger menu button animation
  useEffect(() => {
    const upper = document.getElementsByClassName(
      "header__hamburger-line-upper"
    );
    const lower = document.getElementsByClassName(
      "header__hamburger-line-lower"
    );

    var tl = gsap.timeline({ paused: true, reversed: true });

    tl.to(
      upper,
      0.5,
      { attr: { d: "M8,2 L2,8" }, x: 1, ease: "Power2.easeInOut" },
      "start"
    ).to(
      lower,
      0.5,
      { attr: { d: "M8,8 L2,2" }, x: 1, ease: "Power2.easeInOut" },
      "start"
    );

    document
      .querySelector(".header__hamburger")
      .addEventListener("click", function () {
        tl.reversed() ? tl.play() : tl.reverse();
      });
  }, []);

  const handleMenus = () => {
    setIsVisibleMenu(!isVisibleMenu);
  };

  //menu overlay animation
  useEffect(() => {
    const timeline = gsap.timeline();
    timeline
      .fromTo(
        menuPageRef.current,
        { yPercent: -100, opacity: 0 },
        { yPercent: 0, opacity: 1, duration: 0.9, ease: "power2" }
      )
      .fromTo(
        menusRefs.current,
        { xPercent: -100, opacity: 0 },
        { xPercent: 0, opacity: 1, ease: "power2", stagger: 0.1 }
      );

    !isVisibleMenu && timeline.reverse(!timeline.reversed());
  }, [isVisibleMenu]);

  const addToRefs = (el) => {
    if (menusRefs.current) {
      if (el && !menusRefs.current?.includes(el)) {
        menusRefs.current?.push(el);
      }
    }
  };

  return (
    <div className="header">
      <div
        className={cn("header__top", {
          "header__top--visible": filled,
        })}
      >
        <div
          className={cn("header__menus-minimized", {
            "header__menus-minimized--visible": isVisibleMenu,
          })}
          ref={headerHamburgerRef}
        >
          <div
            className={cn("header__hamburger isNotVisible", {
              "header__hamburger--background": isVisibleMenu,
            })}
            onClick={handleMenus}
          >
            <div
              className={cn("header__hamburger-wrapper", {
                "header__hamburger-wrapper--display": isVisibleMenu,
              })}
              ref={menuRef}
            >
              <svg viewBox="0 0 12 10" height="24px" width="24px">
                <path
                  d="M10,5 L2,5"
                  className={cn("header__hamburger-line-upper", {
                    "header__hamburger-line-upper--color": isVisibleMenu,
                  })}
                />
                <path
                  d="M7,8 L2,8"
                  className={cn("header__hamburger-line-lower", {
                    "header__hamburger-line-lower--color": isVisibleMenu,
                  })}
                />
              </svg>
            </div>
          </div>

          <div
            className={cn("header__contact   isNotVisible", {
              "header__contact--display": isVisibleMenu,
            })}
          >
            {isMobile ? (
              <a href="#contact" className="header__contact-icon">
                <Images.SvgContact />
              </a>
            ) : (
              <a
                href="#contact"
                className="header__contact-link header__contact-link--minimized"
              >
                Contact us
              </a>
            )}
          </div>
        </div>

        {!isMobile && (
          <div className="header__menus" ref={headerMenusRef}>
            <ul className="header__menus-box isHover">
              <li>
                <a href="#key" className="menu__item">
                  About
                </a>
              </li>
              <li>
                <a href="#offer" className="menu__item">
                  Services
                </a>
              </li>
              <li>
                <a href="#process" className="menu__item">
                  Process
                </a>
              </li>
              <li>
                <a href="#technology" className="menu__item">
                  Technology
                </a>
              </li>
            </ul>
            <div className="header__contact  isNotVisible">
              <a href="#contact" className="header__contact-link">
                Contact us
              </a>
            </div>
          </div>
        )}
      </div>
      <Menu
        menuPageRef={menuPageRef}
        isVisible={isVisibleMenu}
        menusRefs={addToRefs}
        menuClick={handleMenus}
      />
    </div>
  );
};
